"use client";
import Script from "next/script";
import { MIXPANEL_TOKEN, QUINO_PROXY_HOSTNAME, QUINO_PROXY_URL } from "@/constants/api";
import { useConsentCookie } from "@/service/cookies/hooks";

const mixpanelScript = `
            (function (f, b) { if (!b.__SV) { var e, g, i, h; window.mixpanel = b; b._i = []; b.init = function (e, f, c) { function g(a, d) { var b = d.split("."); 2 == b.length && ((a = a[b[0]]), (d = b[1])); a[d] = function () { a.push([d].concat(Array.prototype.slice.call(arguments, 0))); }; } var a = b; "undefined" !== typeof c ? (a = b[c] = []) : (c = "mixpanel"); a.people = a.people || []; a.toString = function (a) { var d = "mixpanel"; "mixpanel" !== c && (d += "." + c); a || (d += " (stub)"); return d; }; a.people.toString = function () { return a.toString(1) + ".people (stub)"; }; i = "disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split( " "); for (h = 0; h < i.length; h++) g(a, i[h]); var j = "set set_once union unset remove delete".split(" "); a.get_group = function () { function b(c) { d[c] = function () { call2_args = arguments; call2 = [c].concat(Array.prototype.slice.call(call2_args, 0)); a.push([e, call2]); }; } for ( var d = {}, e = ["get_group"].concat( Array.prototype.slice.call(arguments, 0)), c = 0; c < j.length; c++) b(j[c]); return d; }; b._i.push([e, f, c]); }; b.__SV = 1.2; e = f.createElement("script"); e.type = "text/javascript"; e.async = !0; e.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === f.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//) ? "${QUINO_PROXY_URL}/lib.min.js" : "//${QUINO_PROXY_HOSTNAME}/lib.min.js"; g = f.getElementsByTagName("script")[0]; g.parentNode.insertBefore(e, g); } })(document, window.mixpanel || []);
              
             mixpanel.init('${MIXPANEL_TOKEN}', { debug: false, api_host:'${QUINO_PROXY_URL}', cross_subdomain_cookie: true, track_pageview: true });
          `;

const CLARITY_TRACKING_CODE = process.env.NEXT_PUBLIC_CLARITY_TRACKING_CODE;

const ConsentScripts = () => {
  const { isAccepted } = useConsentCookie();

  if (!isAccepted) return null;

  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-51VSWZ6XF3"></Script>
      <Script id={`blog-page-tracking`}>
        {`
        function loadScript() {        
          var b = document.getElementsByTagName("head")[0],
            c = document.createElement("script");
            (c.type = "text/javascript"),
            (c.src = "https://tracker.metricool.com/resources/be.js"),
            b.appendChild(c);
        }
        loadScript()
        `}
      </Script>
      <Script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7893224341925415"
        crossOrigin="anonymous"
      ></Script>

      <Script id="mixpanel-init-script">{mixpanelScript}</Script>
      <Script id="microsoft-clarity">
        {`
(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${CLARITY_TRACKING_CODE}");
          `}
      </Script>
      <Script id="fb-pixel">
        {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '243939812107467');
fbq('track', 'PageView');
`}
      </Script>

      <Script id="google-analytics">
        {`
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

gtag("js", new Date());

gtag("config", "G-51VSWZ6XF3");
gtag('config', 'AW-16465116531');
`}
      </Script>
    </>
  );
};

export default ConsentScripts;
